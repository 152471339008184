import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    // Testing active-ants
    '2bcf2b66-9073-4cc7-a065-f5744dd470a7': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Testing picqer
    'b7cd4c00-a5b4-45b4-9dce-31e4c9bae28d': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Testing FC_BENE
    '56410a14-428a-49f3-af00-d443b70711f5': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Acceptance active-ants
    '2595fc86-586d-4ab8-be86-23cd0c024758': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Acceptance picqer
    'db2ee0d0-acf4-4254-8b35-b01d0dcaa619': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Acceptance FC_BENE
    '99eb2d31-e430-400c-87e0-8ad425455a02': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Production active-ants
    'ccc2bdbc-d73c-4b43-9cf5-103b7c1edcd0': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Production picqer
    '2de7768a-6194-4e31-9b51-370c19af115a': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
    // Production FC_BENE
    '28782817-40cb-4edb-be8d-5c6a08427aad': {
      expectedDeliveryDays: {
        onStock: '1',
        outOfStock: '10',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '10',
  },
  nextDayDeliveryTimeLimit: '17:30',
  productTypeDeliveryConfig: [
    {
      type: 'MIXED',
      expectedDeliveryDays: '10',
    },
    {
      type: 'SUNGLASSES',
      expectedDeliveryDays: '1',
    },
  ],
})
