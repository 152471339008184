import getConfig from 'next/config'

import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type { CheckoutSettings, Consent, Routes } from '@grandvisionhq/checkout-v2'
import { IntlContextModel } from '@grandvisionhq/state'
import { Util as StoreUtils } from '@grandvisionhq/stores-v2'

interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
  getLabel: IntlContextModel['getLabel']
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

export const basePath = '/checkout'

export const getCheckoutConfig = ({ cart, getLabel }: SettingsInput): CheckoutSettings => {
  const hasSubscription = cart?.contains.subscription
  const consents: Consent[] = hasSubscription
    ? [
        {
          title: getLabel('checkout.subscriptionConsent'),
          value: 'subscriptionConsent',
        },
      ]
    : []

  const routes: Routes = {
    login: {
      label: 'Je account',
      path: `${basePath}/login`,
    },
    shipping: {
      label: getLabel('checkout.shipment.title'),
      path: `${basePath}/shipping`,
    },
    overview: {
      label: getLabel('checkout.summary.title'),
      path: `${basePath}/overview`,
    },
    payment: {
      label: getLabel('checkout.payment.title'),
      path: `${basePath}/payment`,
    },
  }

  return {
    account: {
      implicitAccountCreation: false,
      createAccountRequired: false,
    },
    consent: {
      items: consents,
      implicitTermsAndConditions: true,
    },
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingFormFields: {
      billingAddress: [
        'salutation',
        'firstName',
        'middleName',
        'lastName',
        'streetName',
        'streetNumber',
        'additionalStreetInfo',
        'postalCode',
        'city',
      ],
      contactDetails: ['email', 'phone', 'dateOfBirth'],
    },
    storeFinder: {
      showUseMyLocation: true,
      searchWhitelist: ['storeName', 'postalCode', 'streetName', 'town'] as StoreUtils.SearchKey[],
    },
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: false,
      isServiceStoreEnabled: true,
      phoneNumberRequired: cart?.contains.prescriptionGlasses,
    },
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    routes,
    payments: {
      applePay: {
        merchantName: 'GrandOptical',
      },
    },
    logoVariant: 'alternative',
  }
}
